<template>
  <div class="shop-index" v-loading="loading" style="min-height: 500px;">

    <div class="shop-banner" :style="{backgroundColor:adBgcList[currentIndex]}">
      <el-carousel height="406px" style="max-width: 1920px;min-width: 1200px;margin: 0 auto" @change="bgcChange">
        <el-carousel-item v-for="item in adList" :key="item.adv_id">
          <div
              :style="`background:url(${$img(item.adv_image)}) no-repeat center center;width:100%;height:100%`"
              @click="$router.pushToTab(item.adv_url.url)">
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>

    <div class="content">

      <div style="margin-top: 20px">
        <div style="justify-content: space-between;display: flex;">
          <div v-for="item in magicAdv" style="height: 200px; width: 386px;">
            <el-image :src="$img(item.adv_image)" style="width: 100%;height: 100%"
                      @click="$router.pushToTab(item.adv_url.url)"></el-image>
          </div>
        </div>
      </div>

      <floor :floors="shopInfo.floors"/>


      <div class="tj" v-if="goodsList.length">
        <h5>—————————————&nbsp;&nbsp;&nbsp;&nbsp;最新上架&nbsp;&nbsp;&nbsp;&nbsp;—————————————</h5>
      </div>

      <div style="display: flex;
                            align-items: center;
                            flex-direction: column;
                            justify-content: center;"
      >
        <div class="goods-info">
          <div
              class="item"
              v-for="(item, index) in goodsList"
              :key="item.goods_id"
              @click="$router.pushToTab({ path: '/sku-' + item.sku_id })"
          >
            <img
                class="img-wrap"
                :src="$img(item.sku_image, { size: 'mid' })"
                @error="imageError(index)"
            />

            <div class="goods-name">{{ item.goods_name }}</div>
            <div class="price-wrap">
              <div class="price">
                <p>￥</p>
                {{ item.discount_price }}
              </div>
              <div class="market-price">￥{{ item.market_price }}</div>
            </div>
            <div class="promotion_type" v-if="item.promotion_type == 1">限时折扣</div>
          </div>
        </div>

        <div class="pager">
          <el-pagination
              background
              :pager-count="5"
              :total="total"
              prev-text="上一页"
              next-text="下一页"
              :current-page.sync="currentPage"
              :page-size.sync="pageSize"
              @size-change="handlePageSizeChange"
              @current-change="handleCurrentPageChange"
              hide-on-single-page
          ></el-pagination>
        </div>
      </div>




    </div>


  </div>

</template>

<script>
import {shopInfo, isFollow, unFollow, follow, tree} from "@/api/shop/index"
import {goodsSkuPage} from "@/api/goods/goods"
import {mapGetters} from "vuex"
import {adList} from "@/api/website"
import Floor from "../channel/floor"

export default {
  name: "shop_index",
  components: {Floor},
  data: () => {
    return {
      id: 0,
      currentPage: 1,
      pageSize: 15,
      shopInfo: {}, //店铺信息
      hasFollow: false, //是否关注
      searchContent: "",
      categoryList: [],
      goodsList: [],
      total: 0,
      loading: true,
      keyword: "",
      adList: [],
      adBgcList: [],
      currentIndex: 0,
      magicAdv: []
    }
  },
  computed: {
    ...mapGetters([
      "token",
      "defaultShopImage",
      "addonIsExit",
      "defaultGoodsImage"
    ])
  },
  created() {
    this.id = this.$route.path.replace("/shop-", "")
    this.getAdList()
    this.getShopInfo()
    this.getCategoryList()
    this.getGoodsList()
    this.getfollow()
  },
  watch: {
    $route(curr) {
      this.id = curr.params.pathMatch
      this.getAdList()
      this.getShopInfo()
      this.getCategoryList()
      this.getGoodsList()
      this.getfollow()
    }
  },
  methods: {
    getAdList() {
      adList({keyword: "NS_PC_SHOP_INDEX", site_id: this.id})
          .then(res => {
            this.adList = res.data.adv_list
            for (let i = 0; i < this.adList.length; i++) {
              if (this.adList[i].adv_url)
                this.adList[i].adv_url = JSON.parse(this.adList[i].adv_url)
            }
            for (let i of res.data.adv_list) {
              this.adBgcList.push(i.background)
            }
          }).catch(err => err)

      adList({keyword: "NS_PC_SHOP_INDEX_MAGIC", site_id: this.id}).then(res => {
        this.magicAdv = res.data.adv_list
      }).catch(err => err)

    },
    bgcChange(newIndex, oldIndex) {
      this.currentIndex = newIndex
    },
    //获取店铺信息
    getShopInfo() {
      shopInfo({site_id: this.id})
          .then(res => {
            if (res.code == 0) {
              this.shopInfo = res.data
            }
          })
          .catch(err => {
            this.loading = false
            this.$message.error(err.message)
          })
    },
    //获取店铺商品分类列表
    getCategoryList() {
      tree({site_id: this.id})
          .then(res => {
            this.categoryList = res.data
          })
          .catch(err => {
            this.loading = false
            this.$message.error(err.message)
          })
    },
    //获取店铺商品列表
    getGoodsList() {
      const params = {
        page: this.currentPage,
        page_size: this.pageSize,
        site_id: this.id,
        keyword: this.keyword,
        sort: "desc"
      }
      goodsSkuPage(params || {})
          .then(res => {
            if (res.code == 0 && res.data) {
              let data = res.data
              this.goodsList = data.list
              this.total = res.data.count
              this.loading = false
            }
          })
          .catch(err => {
            this.loading = false
            this.$message.error(err.message)
          })
    },
    //获取店铺关注信息
    getfollow() {
      isFollow({
        site_id: this.id
      })
          .then(res => {
            this.hasFollow = res.data
          })
          .catch(err => {
          })
    },
    //关注与取消
    getIsFollow() {
      if (this.hasFollow) {
        unFollow({site_id: this.id}).then(res => {
          if (res.code == 0 && res.data) {
            this.hasFollow = !this.hasFollow
            this.$message({
              message: "取消关注成功",
              type: "success"
            })
          }
        }).catch(err => {
          console.log(this.shopInfo, "this.shopInfo")
          if (err.message == "token不存在") {
            this.$router.push({
              path: "/login",
              query: {redirect: "/shop-" + this.shopInfo.site_id}
            })
          } else {
            this.$message.error(err.message)
          }
        })
      } else {
        follow({site_id: this.id}).then(res => {
          if (res.code == 0) {
            this.hasFollow = !this.hasFollow
            this.$message({
              message: "关注成功",
              type: "success"
            })
          }
        }).catch(err => {
          if (err.message == "token不存在") {
            this.$router.push({
              path: "/login",
              query: {redirect: "/shop-" + this.shopInfo.site_id}
            })
          } else {
            this.$message.error(err.message)
          }
        })
      }
    },
    goGoodsList() {
      this.$router.push({
        path: "/shop_list",
        query: {site_id: this.id, keyword: this.keyword}
      })
    },
    handlePageSizeChange(num) {
      this.pageSize = num
      this.getGoodsList()
    },
    handleCurrentPageChange(page) {
      this.currentPage = page
      this.getGoodsList()
    },
    imageError(index) {
      this.goodsList[index].sku_image = this.defaultGoodsImage
    },
    go(e) {

      location.href = JSON.parse(e).url
    }
  }
}
</script>
<style lang="scss" scoped>

.shop-index {
  .content {
    width: 1200px;
    margin: 0 auto;
  }

  .tj {
    text-align: center;
    font-size: 30px;
    margin-top: 10px;
  }

  .goods-info {
    width: 1200px;
    margin-top: 5px;
    display: flex;
    flex-wrap: wrap;

    .item {
      width: 224px;
      margin: 10px 20px 0 0;
      border: 1px solid #eeeeee;
      padding: 10px;
      position: relative;
      background: white;
      box-sizing: border-box;

      &:nth-child(5n) {
        margin-right: initial !important;
      }

      &:hover {
        border: 1px solid $base-color;
      }

      .img-wrap {
        width: 202px;
        height: 202px;
        cursor: pointer;
      }

      .goods-name {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        cursor: pointer;

        &:hover {
          color: $base-color;
        }
      }

      .price-wrap {
        display: flex;

        .price {
          display: flex;
          color: $base-color;
          font-size: $ns-font-size-lg;

          p {
            font-size: $ns-font-size-base;
            display: flex;
            align-items: flex-end;
          }
        }

        .market-price {
          color: #838383;
          text-decoration: line-through;
          margin-left: 15px;
        }
      }

      .promotion_type {
        position: absolute;
        top: 0;
        right: 0;
        color: #ffffff;
        background: $base-color;
        border-bottom-left-radius: 10px;
        padding: 3px 8px;
        line-height: 1;
        font-size: $ns-font-size-sm;
      }
    }
  }

  .page {
    display: flex;
    justify-content: center;
    align-content: center;
    padding-top: 20px;
  }

}
</style>
